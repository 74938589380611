<template>
  <div class="profile-edit" v-if="!isProcessing">
    <profile-form @set-postparams="setPostParams" @set-blob="setBlob" @set-canchange="setCanChange"
                  @set-nowvalidationstatus="setNowValidationStatus" :validation-status="validationStatus" :user="user"/>
    <v-btn class="profile-edit__btn" depressed :disabled="!canChange" @click="editUser()">プロフィールを編集する</v-btn>
  </div>
</template>
<script>
import ProfileForm from '@/components/common/form/user.vue'

export default {
  name: 'profile-edit',
  components: { ProfileForm },
  data () {
    return {
      postParams: {
        // プロフィール画像
        icon: '',
        // 名前
        name: '',
        // 会社名・肩書など
        position: '',
        // 自己紹介文
        profile: '',
        // リンク
        link: ''
      },
      // 各入力項目のバリデーション結果
      validationStatus: {
        // プロフィール画像
        // before: 初期状態 or 更新ボタンが押されたタイミングのバリデーション結果
        // now: 現在の入力値に対するバリデーション結果
        icon: {
          before: this,
          now: ''
        },
        // 名前
        name: {
          before: '',
          now: ''
        },
        // 会社名・肩書
        position: {
          before: '',
          now: ''
        },
        // プロフィール
        profile: {
          before: '',
          now: ''
        },
        // URL
        link: {
          before: '',
          now: ''
        }
      },
      // Blobファイル
      blob: null,
      // 画像の格納先のパス名
      path: '',
      // 編集するボタンが押せるかどうか
      canChange: false
    }
  },
  async mounted () {
    // 入力項目の検証結果の初期化
    Object.keys(this.validationStatus).forEach(key => this.validationStatus[key].before = this.VALIDATION_DEFINITION['valid'])

    // ユーザー情報の格納
    if (this.user) {
      Object.keys(this.postParams).forEach(key => {
        this.postParams[key] = this.user[key] || ''
      })
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    },
    /**
     * @return {Object} ユーザー一覧
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Boolean} 名前が使用済みかどうか
     */
    isUsedName () {
      // 自分のuidのObjectを除去
      const { [this.uid]: {}, ...targetUsers } = this.users
      return Object.keys(targetUsers).some( uid => targetUsers[uid].name === this.postParams.name)
    },
    /**
     * @return {Object} バリデーション結果の定義
     */
    VALIDATION_DEFINITION () {
      return this.$store.getters.VALIDATION_DEFINITION
    }
  },
  methods: {
    /**
     * ユーザー情報の更新
     */
    async editUser () {
      this.$store.commit('setSubmitting', true)

      // 入力項目毎のバリデーション結果を更新する
      this.updateValidationStatus()

      const validationStatusSummary = this.validInputValues()
      // 入力項目のなかに無効な値があるときはその状態に応じてテロップを出して登録処理をやめる
      if (validationStatusSummary !== this.VALIDATION_DEFINITION['valid']) {
        if (validationStatusSummary === this.VALIDATION_DEFINITION['requiredBlank']) {
          this.$store.commit('setTelop', { show: true, msg: '入力必須な項目で未入力な箇所があります。', type: 'error' })
        } else {
          this.$store.commit('setTelop', { show: true, msg: '入力された項目に不正な値があります。', type: 'error' })
        }
        this.$store.commit('setSubmitting', false)
        return
      }

      if (this.user['name'] !== this.postParams.name) {
        // 名前の変更がある場合は、ユーザー名が使われているかどうかの確認を行う
        // 全ユーザーを取得
        await this.$store.dispatch('users/getUsers')

        if (this.isUsedName) {
        // ユーザー名が既に使用されている場合
          this.$store.commit('setTelop', { show: true, msg: 'すでに登録されている名前です。\n別の名前を記入して下さい。', type: 'error' })
          this.$store.commit('setSubmitting', false)
          return
        }
      }

      // 画像の更新
      await this.updateImage()

      // ユーザー情報の更新
      await this.updateUser()

      // 処理完了。画面遷移
      this.$store.commit('setTelop', { show: true, msg: 'プロフィール情報を更新しました', type: 'success' })
      this.$router.push({ name: 'home' })
    },
    /**
     * 入力値全体が有効な値かどうか検証する
     * @return {String} VALIDATION_STATUSのいずれかの値
     */
    validInputValues () {
      // 必須項目チェックは別パターンのテロップを出すので優先的にチェックする
      const requiredKeys = ['icon', 'name']
      const setRequiredValues = requiredKeys.every(key => this.validationStatus[key].now !== this.VALIDATION_DEFINITION['requiredBlank'])
      if (!setRequiredValues) {
        return this.VALIDATION_DEFINITION['requiredBlank']
      }

      // 現在の入力値に対して無効な値がないかを検証し, 存在したら最初に見つかった無効な理由を返却する
      const invalidStatusList = Object.keys(this.validationStatus).filter(key => this.validationStatus[key].now !== this.VALIDATION_DEFINITION['valid']).map(key => this.validationStatus[key].now)
      return invalidStatusList.length > 0 ? invalidStatusList[0] : this.VALIDATION_DEFINITION['valid']
    },
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * Blobファイルの代入
     * @param {Blob} blob 格納するBlobファイル
     * @param {String} path 格納する画像の格納先のパス名
     */
    setBlob (blob, path) {
      this.blob = blob
      this.path = path
    },
    /**
     * 登録するボタンが押せるかどうかの結果をセットする. 初回登録時は常にtrue.
     * @param {Boolean} isChanged 初期入力値から変更されたかどうか
     */
    setCanChange (isChanged) {
      this.canChange = isChanged
    },
    /**
     * 現在入力されている値が有効な値かどうかの結果をセットする
     * @param {String} key 入力項目名
     * @param {String} validationStatus バリデーション結果
     */
    setNowValidationStatus (key, validationStatus) {
      this.validationStatus[key].now = validationStatus
    },
    /**
     * 現在入力されている値全体が有効な値かどうかの結果をセットする
     * @param {String} validationStatus バリデーション結果
     */
    setValidationStatusSumally (validationStatus) {
      this.validationStatus.summary = validationStatus
    },
    /**
     * 各入力項目のバリデーション結果を更新する
     */
    updateValidationStatus () {
      Object.keys(this.postParams).forEach(key => this.validationStatus[key].before = this.validationStatus[key].now)
    },
    /**
     * 画像の更新
     */
    async updateImage () {
      // Blobファイルがない場合は終了
      if (!this.blob) return

      // 画像のアップロード
      const url = await this.$store.dispatch('cloudstorage/uploadImage', { blob: this.blob, path: this.path })
      this.postParams.icon = url

      // 既存の画像の削除
      // 削除はいつ終わっても問題ないので、非同期処理
      if (this.user && this.user.icon) this.$store.dispatch('cloudstorage/deleteImage', this.user.icon)
    },
    /**
     * ユーザー情報の更新
     */
    async updateUser () {
      // 日時情報の追加
      this.postParams.updatedAt = new Date()

      // ユーザー情報の更新
      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: this.postParams })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.profile-edit {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__btn {
    display: block;
    margin: 60px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 124px;
      height: 35px;
      padding: 0 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $concept_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $concept_transparent_color !important;
    }
  }
}
</style>
